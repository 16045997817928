import React, { useCallback, useEffect, useState } from 'react';
import { getCookie, setCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBuilding } from '@fortawesome/pro-regular-svg-icons';

import { useRouter } from 'next/router';
import DialogCreateNewTeam from './DialogCreateNewTeam';
import styled from 'styled-components';
import { borderRadius22} from '../../global/Variables.js';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';


export const TeamsOverlay = styled.div`
    background: rgba(0, 0, 0, 0.4);
    z-index: 99999999999;

    position: fixed;
    width: 100%;
    height: 100%;

    display: none;

    &.show {
        display: block;
    }
    .sub-teams {
        padding: 15px !important;

        list-style-type: none;
     
        img,
        svg {
            width: 42px;
            height: 42px;
            max-width: unset;
            border-radius: 50%;
        }

        li {
          
            height: 60px;

            &:hover,
            &.active {
                cursor: pointer;
                span {
                    color: #3d55d7;
                }
            }
        }
        span {
            transition: all 0.3s ease;
            margin-left: 15px;
        }
        .createTeam {
            color: var(--black-40, #999) !important;
            /* Top Menu/Item */
            font-family: 'Nunito Sans';
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: normal !important;
        }
    }

    img {
        width: 48px;
        height: 48px;
    }

    .withoutLogo {
        width: 100%;
        .logoWrapper {
            width: 44px;
            float: left;
            height: 44px;
            border-radius: 50%;
            border: 1px solid lightgrey;
            position: relative;
            svg {
                position: relative;
                width: 15px;
                height: 15px;
                left: 50%;
                color: gray;
                top: 10px;
                transform: translateX(-50%);
            }
        }
        span {
            margin-left: 13px !important;
            top: 10px;
            position: relative;
        }
    }

    .mn-sub {
        width: 260px;
        position: absolute;

        padding: 0;
       
        border-bottom-left-radius: 22px;
        border-bottom-right-radius: 0;

        transition: all 0.3s ease;
        background: white;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border-radius: ${borderRadius22};
        padding-top: 5px;
        height: fit-content;
        padding-left: 10px;
        padding-bottom: 10px;

        .dividerText {
            font-size: 18px !important;
            position: relative;
            color: rgba(255, 255, 255, 0.6);

            text-align: center;

            font-style: normal;
            font-weight: 300;
            line-height: normal;
            width: 90%;
            position: relative;
            margin: auto auto;
            display: block;
        }
    }
`;

export default function DialogSwitchTeams({ toggleSwitchTeams, typeEditor,userTeams, loadUserInfoAPI ,plugin}) {
    const router = useRouter();

    const [showSwitchTeams, changeShowSwitchTeams] = useState(false);

    const toggleSwitchTeamsInside = useCallback((): void => {

        if (showSwitchTeams) {
            changeShowSwitchTeams(false);
        } else {
            changeShowSwitchTeams(true);
        }
    }, []);
    useEffect(() => {
        toggleSwitchTeams && toggleSwitchTeams(toggleSwitchTeamsInside);
    }, [toggleSwitchTeams, toggleSwitchTeamsInside]);

    const closeModalOutsideClick = e => {
        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeShowSwitchTeams(false);
        }
    };
    let handleClickCreateNewTeam = () => {

    };

    const switchTeam = teamUUID => {
        var tokenMain = getCookie('userTeam');

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/switch-team/' + teamUUID, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + tokenMain, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                //  clientLogo
                //
                if (data.message !== undefined) {
                    Sentry.captureException('auth/switch-team/' + teamUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
                if (data.token !== undefined && data.token !== null) {


                    if(plugin){
                        setCookie('userTeam', data.token, {maxAge: 10 * 365 * 24 * 60 * 60} );
                        setCookie('userTeamUUID', teamUUID, {maxAge: 10 * 365 * 24 * 60 * 60} );

                        setCookie("pluginLoginSessionTeamToken", data.token, {maxAge: 10 * 365 * 24 * 60 * 60});
                        setCookie('reloadProductsPlugin', true);

                        router.push('/plugin/index-plugin');

                    }else{
                        setCookie('userTeam', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });
                        setCookie('userTeamUUID', teamUUID, { maxAge: 10 * 365 * 24 * 60 * 60 });


                        if(typeEditor){
                            router.push("/");

                        } else {
                            router.reload(window.location.pathname);
                        }


                    }

                }
            });
    };
    return (
        <>
            <TeamsOverlay className={`  ${showSwitchTeams ? 'show' : ''}`} data-closemodal={true} onClick={e => closeModalOutsideClick(e)}>
                <ul
                    className={` mn-sub sub-teams ${showSwitchTeams ? 'show' : ''}`}
                    style={{
                        borderRadius: '22px',
                        paddingTop: '5px',
                        maxHeight: "88%",
                        overflowY: "scroll"
                    }}
                >
                    {Object.keys(userTeams).map(key => {
                        if (userTeams[key]['logo'] !== null) {
                            return (
                                <li
                                    key={userTeams[key]['uuid']}
                                    className={getCookie('userTeamUUID') === userTeams[key]['uuid'] ? 'active teamSwitcher' : 'teamSwitcher'}
                                    onClick={() => switchTeam(userTeams[key]['uuid'])}
                                >
                                    <a>
                                        <img src={userTeams[key]['logo']} />
                                        <span className={'spanAdjust'}>{userTeams[key]['name'].length > 20 ? userTeams[key]['name'].substring(0, 20) + '...' : userTeams[key]['name']}</span>
                                    </a>
                                </li>
                            );
                        } else {
                            return (
                                <li
                                    key={userTeams[key]['uuid']}
                                    className={getCookie('userTeamUUID') === userTeams[key]['uuid'] ? 'active teamSwitcher withoutLogo' : 'teamSwitcher withoutLogo'}
                                    onClick={() => switchTeam(userTeams[key]['uuid'])}
                                >
                                    <div className={'logoWrapper'}>
                                        <FontAwesomeIcon icon={faBuilding} />
                                    </div>

                                    <div className={'textWrapper'}>
                                        <span className={'spanAdjust'}>{userTeams[key]['name'].length > 20 ? userTeams[key]['name'].substring(0, 20) + '...' : userTeams[key]['name']}</span>
                                    </div>
                                </li>
                            );
                        }
                    })}

                    <li key={'hr'} style={{ height: '20px', marginTop: '-10px' }}>
                        <div style={{ width: '100%' }}>
                            <hr />
                        </div>
                    </li>

                    <li key={'createteam'} style={{ height: '30px' }} onClick={() => handleClickCreateNewTeam()}>
                        <a className={'createTeam'} style={{ marginTop: '5px' }}>
                            <FontAwesomeIcon
                                style={{
                                    height: '1.1em',
                                    filter: 'none !important',
                                    width: '17px',
                                    marginRight: '10px',
                                }}
                                icon={faPlus}
                            />
                            <FormattedMessage {...messages.modal_switch_team_button_create_new_team}></FormattedMessage>
                        </a>
                    </li>
                </ul>
            </TeamsOverlay>

            <DialogCreateNewTeam
                loadTeamsForSelect={loadUserInfoAPI}
                toggleCreateNewTeam={(toggleCreateNewTeam: () => void) => {
                    handleClickCreateNewTeam = toggleCreateNewTeam;
                }}
            />
        </>
    );
}
