import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock, faUnlock} from "@fortawesome/pro-regular-svg-icons";
import {getCookie} from "cookies-next";
import * as Sentry from "@sentry/nextjs";
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';

export default function DialogLockVersion ({productLocked, setVersionIsLocked,masterProductID,toggleLockVersion,closeAfterFinish,  loadProductDetailWithVersions,
                                               productID}) {

    const [showLockDialog, setShowLockDialog] = useState(false);
    const [showLockDialogTransition, setShowLockDialogTransition] = useState(false);


    const toggleLockVersionInside = useCallback((): void => {
        if(showLockDialog){
            changeLockShow(false);
        }else{
            changeLockShow(true);
        }


    }, []);
    useEffect(() => {

        toggleLockVersion && toggleLockVersion(toggleLockVersionInside);
    }, [toggleLockVersion, toggleLockVersionInside]);



    const lockProductVersion = (variable) => {


        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+masterProductID+"/versions/"+productID, {
            method: 'PUT',
            body: JSON.stringify(    {
                "locked" : variable
            }),
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){

                if(data.message !== undefined){
                    Sentry.captureException("Lock product version products/"+masterProductID+"/versions/"+productID+": "+ data.message + " : " + getCookie("userMail"));
                }
                if(data.message === undefined){
                    changeLockShow(false);
                    if(closeAfterFinish){
                        changeLockShow(false);
                        loadProductDetailWithVersions();
                    }

                    if(setVersionIsLocked){
                        setVersionIsLocked(data.data.locked.locked);
                    }
                }
            });
    };

    const changeLockShow = (variable) => {
        if(variable === true){
            setShowLockDialog(true);

            setTimeout(function() {
                setShowLockDialogTransition(variable);
            },2);


        } else{

            setShowLockDialogTransition(false);
            setTimeout(function() {
                setShowLockDialog(variable);
            },300);
        }
    };
    const closeModalOutsideClick = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeLockShow(false);

        }
    }


    return (
        <>


                <ModalsWrapper className={`  ${showLockDialogTransition ? 'showTransition' : 'hideTransition'} ${!showLockDialog ? 'hiddeComplete' : ''}`}
                               data-closemodal={true}
                               onClick={(e)=> closeModalOutsideClick(e)}
                >

                    <ModalWindow id={'lockProductDialog'}
                                 style={window.innerWidth < 900 ?{ minHeight: 'unset', height: '230px' } : { minHeight: 'unset', height: '190px' }}
                                 className={"center modal_dialog_small"}>
                        <div className={'modal-header'}>
                            <h4>  <FormattedMessage {...messages.modal_version_lock_title}></FormattedMessage></h4>
                        </div>




                        <div className={'modal-content shareProduct'} style={{display:"block"}}>
                            {productLocked ?
                                <span style={{ fontSize: "16px"}}>    <FormattedMessage {...messages.modal_version_unlock_description}></FormattedMessage></span>
                                :
                                <span style={{ fontSize: "16px"}}> <FormattedMessage {...messages.modal_version_lock_description}></FormattedMessage></span>}


                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal  className={"borderBlue btn-circle"} onClick={() => changeLockShow(false)}>
                                    <FormattedMessage {...messages.modal_version_lock_button_cancel}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal  className={"backgroundBlue btn-circle "} data-uuid={productID}
                                                    onClick={productLocked ? () => lockProductVersion(false) : () => lockProductVersion(true)}>

                                    {productLocked ?
                                        <div >  <FontAwesomeIcon   icon={faUnlock}/> <FormattedMessage {...messages.modal_version_unlock_button_unlock_version}></FormattedMessage></div>
                                        :
                                        <div >  <FontAwesomeIcon   icon={faLock}/> <FormattedMessage {...messages.modal_version_lock_button_lock_version}></FormattedMessage></div>}


                                </GlobalButtonFinal>
                            </div>

                        </div>
                    </ModalWindow>




                </ModalsWrapper>


        </>
    )
}